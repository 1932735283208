import * as apiClient from 'resources/api.client';

export function fetchCashbook(garageId, data) {
  return apiClient.get('cashbook', { garageId, ...data });
}

export function getCashbookCSV(garageId, data) {
  return apiClient.get('cashbook/csv', { garageId, ...data });
}

export function createEntry(garageId, data) {
  return apiClient.post('cashbook', { garageId }, data);
}

export function cancelEntry(cashbookId, garageId, data) {
  return apiClient.post(`cashbook/${cashbookId}/cancel`, { garageId }, data);
}

export function getNextCashbookEntryNumber(garageId) {
  return apiClient.get('cashbook/nextEntryNumber', { garageId });
}
