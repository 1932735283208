import { faMoneyBillTransfer } from 'fontawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import React from 'react';

export class BankingSyncCompletedNotification {
  constructor(notification, { history }) {
    this.notification = notification;
    this.history = history;
  }

  Icon({ className }) {
    return <FontAwesomeIcon className={className} icon={faMoneyBillTransfer} />;
  }

  getNotificationText(polyglot) {
    const { metaData } = this.notification;
    return <div>
      <strong>
        {polyglot.t('notifications.bankingSyncCompleted.title', { syncCount: metaData.syncCount })}
      </strong>
      <br></br>
      {polyglot.t('notifications.bankingSyncCompleted.text')}
    </div>;
  }

  getStyleType() {
    return 'success';
  }

  onClickAction() {
    this.history.push('/banking');
  }
}
