import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { debounce } from 'lodash/function';
import pageStyles from 'styles/page.pcss';
import polyglot from 'services/localization';
import InputModern from 'components/common/inputModern';
import DeviceService from 'services/device.service';

import styles from './searchInputModern.styles.pcss';

class SearchInputModern extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onDebounce: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    totalAmount: PropTypes.number,
    maxLength: PropTypes.number,
    dataTest: PropTypes.string,
    width: PropTypes.number,
    showAmount: PropTypes.bool,
  };

  static defaultProps = {
    id: null,
    className: '',
    placeholder: '',
    totalAmount: undefined,
    maxLength: 500,
    dataTest: '',
    width: null,
    showAmount: true,
    onDebounce: () => null,
  };

  searchInput = React.createRef();

  onChange = (value) => {
    const { onChange } = this.props;
    let newVal = value;
    if (newVal !== '' && newVal.trim() === '') newVal = '';
    onChange(newVal);
    this.searchDebounce();
  };

  onClearClicked = () => {
    this.onChange('');
    setTimeout(() => {
      this.focusInput();
    }, 100);
  };

  focusInput() {
    if (this.searchInput.current && this.searchInput.current.inputRef.current) {
      this.searchInput.current.inputRef.current.focus();
      this.searchInput.current.inputRef.current.select();
    }
  }

  searchDebounce = debounce(() => {
    const { onDebounce } = this.props;
    onDebounce();
  }, 500, { leading: false, trailing: true });

  render() {
    const {
      className,
      placeholder,
      value,
      maxLength,
      totalAmount,
      dataTest,
      width,
      showAmount,
      id,
    } = this.props;

    const inputStyles = {};

    if (width) {
      inputStyles.width = width;
    }
    return (
      <div style={inputStyles} className={className}>
        <InputModern
          id={id}
          ref={this.searchInput}
          className={styles.searchInput}
          type={InputModern.TYPES.SEARCH}
          onChange={({ target }) => this.onChange(target.value)}
          onClearClicked={this.onClearClicked}
          value={value}
          showCancel
          maxLength={maxLength}
          autoFocus={!(DeviceService.isMobile && DeviceService.isTouchDevice)}
          selectOnFocus
          placeholder={placeholder}
          dataTest={dataTest}
        />

        {!Number.isNaN(+totalAmount) && (
        <>
          {showAmount && (
          <div className={cx(pageStyles.pageActionsSearchResult)}>
            {`${totalAmount} ${totalAmount > 1 ? polyglot.t('actions.results') : polyglot.t('actions.result')}`}
          </div>
          )}
        </>
        )}
      </div>
    );
  }
}

export default SearchInputModern;
