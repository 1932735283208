import { faFileCircleXmark } from 'fontawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import React from 'react';

export class SdiRejectedNotification {
  constructor(notification, { history }) {
    this.notification = notification;
    this.history = history;
  }

  Icon({ className }) {
    return <FontAwesomeIcon
      icon={faFileCircleXmark}
      className={className}
    />;
  }

  getNotificationText(polyglot) {
    const sdiText = polyglot.t('notifications.sdiText', {
      invoiceNumber: this.notification.metaData.invoiceNumber || '',
    });
    const { error } = this.notification.metaData;
    const errorCode = error?.Codice;
    let errorMessage = error?.Descrizione;
    if (errorCode && polyglot.has(`notifications.sdiRejectedErrors.${errorCode}`)) {
      errorMessage = polyglot.t(`notifications.sdiRejectedErrors.${errorCode}`);
    }
    return `${sdiText}${errorMessage ? `\n\n${errorMessage}` : ''}`;
  }

  getStyleType() {
    return 'error';
  }

  onClickAction() {
    if (this.notification.metaData?.invoiceId) {
      this.history.push(`/invoices/view/${this.notification.metaData.invoiceId}`);
    }
  }
}
