import { connect } from 'react-redux';

import { isThirdPartyToolActive } from 'resources/garage/garage.selectors';

import VecosIntegration from './vecosIntegration';

export default connect(
  (state) => ({
    isVecosActive: isThirdPartyToolActive('vecos', state),
  }),
)(VecosIntegration);
