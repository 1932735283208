import React from 'react';
import PropTypes from 'prop-types';
import { MdPhotoCamera as PhotoCamera } from 'react-icons/md';
import CashbookArrow from './svg/cashbookArrow';
import CashbookRegister from './svg/cashbookRegister';
import EditorList from './svg/editorList';
import ImportLink from './svg/importLink';
import OfferIcon from './svg/offerIcon';
import InvoiceIcon from './svg/invoiceIcon';
import EndTestOperationWizzardPageOne from './svg/endTestOperationWizzardPageOne';
import SmileySad from './svg/smileySad';
import SmileyHappy from './svg/smileyHappy';
import SmileyMeh from './svg/smileyMeh';
import SmileyMehHappy from './svg/smileyMehHappy';
import SmileySadMeh from './svg/smileySadMeh';
import FeedbackPicture from './svg/feedBackPicture';
import ThereAreNoEntities from './svg/thereAreNoEntities';
import Search from './svg/search';
import IntegrationSearchNotFound from './svg/integrationSearchNotFound';
import ThanksForSubmission from './svg/thanksForSubmission';
import RegistrationIcon from './svg/registrationIcon';
import Close from './svg/close';
import SocialPage from './svg/socialPage';
import TachoBar from './svg/tachoBar';
import TachoNeedle from './svg/tachoNeedle';
import CustomerMode from './svg/customerMode';
import Blob from './svg/blob';
import Webinar from './svg/webinar';
import CameraViewfinder from './svg/cameraViewfinder';
import Plus from './svg/plus';
import FilePlus from './svg/filePlus';
import CarPlus from './svg/carPlus';
import ManualIcon from './svg/manualIcon';
import BlobV2 from './svg/blobV2';
import BlobV3 from './svg/blobV3';
import BestCustomersEmpty from './svg/bestCustomersEmpty';
import Business from './svg/business';
import NoConnectionIcon from './svg/noConnectionIcon';
import RegistrationDetection from './svg/registrationDetection';
import ClientDataSvg from './svg/clientDataSvg';
import BlobV4 from './svg/blobV4';
import ClientBirthday from './svg/clientBirthday';
import RecycleBinEmptyState from './svg/recycleBinEmptyState';
import HomeOffice from './svg/homeOffice';
import EmptyPositions from './svg/emptyPositions';
import NotFound from './svg/notFound';
import Arrow from './svg/arrow';
import CreditCardBack from './svg/creditCardBack';
import Payment from './svg/payment';
import FinanzOnlineCashRegister from './svg/finanzOnlineCashRegister';
import FinanzOnlineLogo from './svg/finanzOnlineLogo';
import Carta from './svg/carta';
import InvoiceTransferCycle from './svg/invoiceTransferCycle';

const Icon = ({ type, ...rest }) => {
  switch (type) {
    case 'cashbookArrow':
      return <CashbookArrow {...rest} />;
    case 'photoCamera':
      return <PhotoCamera {...rest} />;
    case 'editorList':
      return <EditorList {...rest} />;
    case 'importLink':
      return <ImportLink {...rest} />;
    case 'offerIcon':
      return <OfferIcon {...rest} />;
    case 'invoiceIcon':
      return <InvoiceIcon {...rest} />;
    case 'endTestImage':
      return <EndTestOperationWizzardPageOne {...rest} />;
    case 'smileySad':
      return <SmileySad {...rest} />;
    case 'smileyHappy':
      return <SmileyHappy {...rest} />;
    case 'smileyMeh':
      return <SmileyMeh {...rest} />;
    case 'smileyMehHappy':
      return <SmileyMehHappy {...rest} />;
    case 'smileySadMeh':
      return <SmileySadMeh {...rest} />;
    case 'feedbackPicture':
      return <FeedbackPicture {...rest} />;
    case 'thereAreNoEntities':
      return <ThereAreNoEntities {...rest} />;
    case 'search':
      return <Search {...rest} />;
    case 'registrationIcon':
      return <RegistrationIcon {...rest} />;
    case 'integrationSearchNotFound':
      return <IntegrationSearchNotFound {...rest} />;
    case 'thanksForSubmission':
      return <ThanksForSubmission {...rest} />;
    case 'close':
      return <Close {...rest} />;
    case 'socialPage':
      return <SocialPage {...rest} />;
    case 'tachoBar':
      return <TachoBar {...rest} />;
    case 'tachoNeedle':
      return <TachoNeedle {...rest} />;
    case 'customerMode':
      return <CustomerMode {...rest} />;
    case 'blob':
      return <Blob {...rest} />;
    case 'cameraViewfinder':
      return <CameraViewfinder {...rest} />;
    case 'plus':
      return <Plus {...rest} />;
    case 'filePlus':
      return <FilePlus {...rest} />;
    case 'carPlus':
      return <CarPlus {...rest} />;
    case 'blobV2':
      return <BlobV2 {...rest} />;
    case 'blobV3':
      return <BlobV3 {...rest} />;
    case 'blobV4':
      return <BlobV4 {...rest} />;
    case 'manualIcon':
      return <ManualIcon {...rest} />;
    case 'bestCustomersEmpty':
      return <BestCustomersEmpty {...rest} />;
    case 'webinar':
      return <Webinar {...rest} />;
    case 'business':
      return <Business {...rest} />;
    case 'noConnectionIcon':
      return <NoConnectionIcon {...rest} />;
    case 'registrationDetection':
      return <RegistrationDetection {...rest} />;
    case 'clientData':
      return <ClientDataSvg {...rest} />;
    case 'clientBirthday':
      return <ClientBirthday {...rest} />;
    case 'recycleBinEmptyState':
      return <RecycleBinEmptyState {...rest} />;
    case 'homeOffice':
      return <HomeOffice {...rest} />;
    case 'emptyPositions':
      return <EmptyPositions {...rest} />;
    case 'notFound':
      return <NotFound {...rest} />;
    case 'arrow':
      return <Arrow {...rest} />;
    case 'creditCardBack':
      return <CreditCardBack {...rest} />;
    case 'payment':
      return <Payment {...rest} />;
    case 'finanzOnlineCashRegister':
      return <FinanzOnlineCashRegister {...rest} />;
    case 'finanzOnlineLogo':
      return <FinanzOnlineLogo {...rest} />;
    case 'carta':
      return <Carta {...rest} />;
    case 'invoiceTransferCycle':
      return <InvoiceTransferCycle {...rest} />;
    case 'cashbookRegister':
      return <CashbookRegister {...rest} />;
    default:
      return null;
  }
};

Icon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Icon;
