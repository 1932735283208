import React, { Component } from 'react';
import PropTypes from 'prop-types';
import polyglot from 'services/localization';
import { getFormatedDateString } from 'helpers/date';
import withDeviceInfo from 'components/higherOrderComponents/withDeviceInfo';
import InteractableDiv from 'components/common/interactableDiv';
import cx from 'classnames';
import styles from './notificationsCard.styles.pcss';
import { getTypeImpl } from '../../types';

class NotificationsCard extends Component {
  static propTypes = {
    notification: PropTypes.shape({
      _id: PropTypes.string,
      type: PropTypes.string,
      createdOn: PropTypes.string,
      metaData: PropTypes.shape({
        invoiceId: PropTypes.string,
      }),
    }).isRequired,
    onClick: PropTypes.func,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    isProcessed: PropTypes.bool,
  };

  static defaultProps = {
    onClick: () => {},
    isProcessed: false,
  };

  onCardClick = (typeImpl) => {
    const { onClick, notification } = this.props;

    onClick(notification._id);
    typeImpl.onClickAction();
  };

  CLASS_CARD_STYLE = {
    error: styles.containerCardErrorBackground,
    warning: styles.containerCardWarningBackground,
    success: styles.containerCardSuccessBackground,
  };

  CLASS_ICON_STYLE = {
    error: styles.containerCardErrorIcon,
    warning: styles.containerCardWarningIcon,
    success: styles.containerCardSuccessIcon,
  };

  render() {
    const { notification, history, isProcessed } = this.props;
    const typeImpl = getTypeImpl(notification, { history });
    const { Icon } = typeImpl;
    return (
      <InteractableDiv
        className={cx(
          styles.containerCard,
          this.CLASS_CARD_STYLE[typeImpl.getStyleType()],
          { [styles.containerCardProcessed]: isProcessed },
        )}
        dataTest={`notification-card-${notification._id}`}
        onClick={() => this.onCardClick(typeImpl)}
      >
        <div className={styles.containerCardData}>
          <Icon className={cx(
            styles.containerCardDataIcon,
            this.CLASS_ICON_STYLE[typeImpl.getStyleType()],
            { [styles.containerCardDataIconProcessed]: isProcessed },
          )} />
          <div className={styles.containerCardDataText}>
            {typeImpl.getNotificationText(polyglot, notification)}
          </div>
          <div className={styles.containerCardDataTimestamp}>
            {getFormatedDateString(polyglot, notification.createdOn)}
          </div>
        </div>
      </InteractableDiv>
    );
  }
}

export default withDeviceInfo(NotificationsCard);
