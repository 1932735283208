import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import { faDownload, faShareNodes } from 'fontawesome/pro-solid-svg-icons';

import polyglot from 'services/localization';
import DeviceService from 'services/device.service';
import { downloadOrShareFileFromBlob, fetchBlob, shouldShareFile } from 'helpers/file';
import parseError from 'helpers/parseError';
import toastService from 'helpers/toastService';
import ButtonModern from 'components/common/buttonModern';
import InteractableDiv from 'components/common/interactableDiv';
import styles from './buttonDownload.styles.pcss';

class ButtonDownload extends Component {
  static propTypes = {
    key: PropTypes.string,
    className: PropTypes.string,
    dataTest: PropTypes.string,
    styleType: PropTypes.string,
    fileName: PropTypes.string,
    title: PropTypes.string.isRequired,
    getDownloadUrl: PropTypes.func,
    getDownloadedBlob: PropTypes.func,
    showButtonStyle: PropTypes.bool,
    withText: PropTypes.bool,
  };

  static defaultProps = {
    key: null,
    className: '',
    dataTest: null,
    styleType: ButtonModern.STYLE.SELECT,
    fileName: 'File',
    showButtonStyle: true,
    withText: false,
    getDownloadUrl: () => null,
    getDownloadedBlob: () => null,
  };

  state = {
    shouldShareFiles: DeviceService.isMobile
      && [DeviceService.OS_LIST.I_OS, DeviceService.OS_LIST.MAC_OS].includes(DeviceService.OS)
      && navigator.share,
    isLoading: false,
  };

  onDownloadPdf = async () => {
    const { fileName, getDownloadUrl, getDownloadedBlob } = this.props;

    this.setState({ isLoading: true });
    try {
      const url = await getDownloadUrl();
      const blob = url ? await fetchBlob(url) : await getDownloadedBlob();
      await downloadOrShareFileFromBlob(blob, fileName);
    } catch (e) {
      const msg = parseError(e);
      toastService.showError(msg || polyglot.t('misc.unknownError'));
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const {
      key, className, styleType, title, showButtonStyle, withText, dataTest,
    } = this.props;
    const { isLoading } = this.state;
    const showShareButton = shouldShareFile();
    return showButtonStyle ? (
      <ButtonModern
        key={key}
        className={cx(styles.buttonDownload, className)}
        styleType={styleType}
        onClick={this.onDownloadPdf}
        isLoading={isLoading}
        title={title}
        dataTest={dataTest}
      >
        <FontAwesomeIcon icon={showShareButton ? faShareNodes : faDownload} />
        {withText && (showShareButton ? polyglot.t('actions.share') : polyglot.t('actions.download'))}
      </ButtonModern>
    ) : (
      <InteractableDiv key={key} dataTest={dataTest} onClick={this.onDownloadPdf}>
        <FontAwesomeIcon
          className={className}
          icon={showShareButton ? faShareNodes : faDownload}
        />
      </InteractableDiv>
    );
  }
}

export default ButtonDownload;
