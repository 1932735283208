import { faMoneyBillTransfer } from 'fontawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import React from 'react';

export class BankingActionRequiredNotification {
  constructor(notification, { history }) {
    this.notification = notification;
    this.history = history;
  }

  Icon({ className }) {
    return <FontAwesomeIcon className={className} icon={faMoneyBillTransfer} />;
  }

  getNotificationText(polyglot) {
    return <div>
      <strong>
        {polyglot.t('notifications.bankingActionRequired.title')}
      </strong>
      <br></br>
      {polyglot.t('notifications.bankingActionRequired.text')}
    </div>;
  }

  getStyleType() {
    return 'warning';
  }

  onClickAction() {
    this.history.push('/banking');
  }
}
