import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Document, Page } from 'components/pdf';

import { getFileExtension, printObjectUrl } from 'helpers/file';
import polyglot from 'services/localization';
import modalStyles from 'styles/modal.pcss';
import buttonStyles from 'styles/button.pcss';
import { track } from 'services/tracking.service';
import styles from './fullImageView.styles.pcss';
import ResponsiveModal from '../responsiveModal';
import ImageEditor from '../imageEditor';
import ButtonModern from '../buttonModern';

export default class FullImageView extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    fetchImage: PropTypes.func,
    name: PropTypes.string,
  };

  static defaultProps = {
    name: '',
    url: '',
    isOpen: true,
    fetchImage: () => {},
  };

  state = {
    convertedPngFile: null,
    image: null,
    numPages: 1,
    currentPageNumber: 1,
  };

  componentDidMount() {
    this.loadImage();
  }

  getCanvas = () => {
    const c = document.getElementsByTagName('canvas');
    if (c.length) {
      return c[0];
    }
    return null;
  };

  dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  loadImage = async () => {
    const { fetchImage } = this.props;

    const result = await fetchImage(true);
    if (!result) return;
    this.setState({ image: URL.createObjectURL(result) });
  };

  onPdfLoaded = () => {
    const { name } = this.props;
    const c = this.getCanvas();
    if (c !== null) {
      const convertedPngFile = this.dataURLtoFile(c.toDataURL('image/jpeg'), `${name.split('.pdf')[0]}.jpg`);
      this.setState({ convertedPngFile });
    }
  };

  handlePrint = () => {
    const { name } = this.props;
    const { image } = this.state;
    track({ event: 'User started file-print' });
    if (getFileExtension(name) === 'pdf') {
      const newTab = window.open('', '_blank');
      newTab.document.write(polyglot.t('actions.pdfLoading'));
      newTab.location = image;
    } else {
      printObjectUrl(image, name);
    }
  };

  renderPageButtons = () => {
    const { numPages, currentPageNumber } = this.state;
    const buttons = [];
    for (let i = 1; i <= numPages; i += 1) {
      buttons.push(
        <ButtonModern
          onClick={() => this.setState({ currentPageNumber: i, convertedPngFile: null })}
          styleType={currentPageNumber === i ? 'add' : 'select'}
          size="small"
        >
          {i}
        </ButtonModern>,
      );
    }
    return buttons;
  };

  render() {
    const {
      name,
      onClose,
    } = this.props;
    const { convertedPngFile, currentPageNumber, image } = this.state;
    const { isOpen } = this.props;

    return (
      <ResponsiveModal
        isOpen={isOpen}
        onClose={onClose}
        className={styles.imageContainer}
        title={name}
      >
        {getFileExtension(name) === 'pdf' && !convertedPngFile ? (
          <div className={cx(styles.paper, styles.paperFlex)}>
            <Document
              className={styles.paperPicture}
              file={image}
              onLoadSuccess={({ numPages }) => { this.setState({ numPages }); } }
            >
              <Page
                className={styles.paperPicture}
                key="page_1"
                pageNumber={currentPageNumber}
                scale={2}
                renderTextLayer={false}
                onRenderSuccess={() => {
                  this.onPdfLoaded();
                }}
              />
              <span />
            </Document>
          </div>
        ) : <ImageEditor
            zoomEnabled
            hasZoomButtons
            autoHeight
            src={!convertedPngFile ? image : null}
            file={convertedPngFile || null}
          />
        }
        {this.state.numPages > 1 && (
          <div className={styles.paperPages}>
            {this.renderPageButtons().map((button) => button)}
          </div>
        )}
        <div className={cx(modalStyles.footer, styles.fullImageViewFooter)}>
          <button
            className={cx(buttonStyles.button, buttonStyles.buttonRemove)}
            onClick={() => this.handlePrint()}
          >
            {polyglot.t('actions.print')}
          </button>
          <button
            className={cx(buttonStyles.button, buttonStyles.buttonRemove)}
            onClick={this.props.onClose}
          >
            {polyglot.t('actions.cancel')}
          </button>
        </div>
      </ResponsiveModal>
    );
  }
}
